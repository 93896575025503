var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-title align-items-center"},[_c('div',{staticClass:"col-md-3 col-xl-6"},[_c('h4',{staticClass:"mb-1 mt-0"},[_vm._v(_vm._s(_vm.pageTitle)+" "),(_vm.currentCustomer.name)?_c('span',[_vm._v(": "+_vm._s(_vm.currentCustomer.name))]):_vm._e()])]),_c('div',{staticClass:"col-md-9 col-xl-6 text-md-right"},[_c('div',{staticClass:"mt-4 mt-md-0"},[_c('button',{staticClass:"btn btn-dark mr-2 mb-3 mb-sm-0",attrs:{"type":"button"},on:{"click":function($event){return _vm.goback()}}},[_c('i',{staticClass:"mr-1"}),_vm._v(" Go Back ")])])])]),(_vm.currentCustomer)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-xl-7"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form-horizontal",attrs:{"autocomplete":"nope"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveCustomer)}}},[_c('h4',{staticClass:"header-title"},[_vm._v("Location Name")]),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{staticClass:"mt-2 float-right",attrs:{"for":"inputEmail3"}},[_vm._v("Name")])]),_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"locationname","rules":{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max: 100 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"locationname","autocomplete":"nope"},model:{value:(_vm.currentCustomer.name),callback:function ($$v) {_vm.$set(_vm.currentCustomer, "name", $$v)},expression:"currentCustomer.name"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{staticClass:"mt-2 float-right",attrs:{"for":"inputEmail3"}},[_vm._v("ABN")])]),_c('b-col',{attrs:{"sm":"3"}},[_c('ValidationProvider',{attrs:{"name":"abn","rules":{ regex: /^[0-9]+$/, required: true, min:11, max: 11 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"abn","autocomplete":"nope"},model:{value:(_vm.currentCustomer.abn),callback:function ($$v) {_vm.$set(_vm.currentCustomer, "abn", $$v)},expression:"currentCustomer.abn"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('h4',{staticClass:"header-title"},[_vm._v("Address")]),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{staticClass:"mt-2 float-right",attrs:{"for":"inputEmail3"}},[_vm._v("Search Address:")])]),_c('b-col',{attrs:{"sm":"7"}},[_c('addressSuggest',{on:{"getAddress":_vm.getAddress}})],1)],1),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{staticClass:"mt-2 float-right",attrs:{"for":"inputEmail3"}},[_vm._v("Country:")])]),_c('b-col',{attrs:{"sm":"4"}},[_c('ValidationProvider',{attrs:{"rules":{ required: true, max: 100 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{staticClass:"mb-2",attrs:{"name":"state"},on:{"change":_vm.onCountrySelected},model:{value:(_vm.currentCustomer.country),callback:function ($$v) {_vm.$set(_vm.currentCustomer, "country", $$v)},expression:"currentCustomer.country"}},_vm._l((_vm.countryList),function(item,index){return _c('option',{key:index,attrs:{"text":item.country},domProps:{"value":item.countryCode}},[_vm._v(_vm._s(item.country))])}),0),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{staticClass:"mt-2 float-right",attrs:{"for":"inputEmail3"}},[_vm._v("State:")])]),_c('b-col',{attrs:{"sm":"4"}},[_c('ValidationProvider',{attrs:{"rules":{ required: true, max: 100 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{staticClass:"mb-2",attrs:{"name":"state"},model:{value:(_vm.currentCustomer.state),callback:function ($$v) {_vm.$set(_vm.currentCustomer, "state", $$v)},expression:"currentCustomer.state"}},_vm._l((_vm.stateList),function(item,index){return _c('option',{key:index,attrs:{"text":item.state},domProps:{"value":item.stateCode}},[_vm._v(_vm._s(item.state))])}),0),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{staticClass:"mt-2 float-right",attrs:{"for":"inputEmail3"}},[_vm._v("Address Line 1:")])]),_c('b-col',{attrs:{"sm":"5"}},[_c('ValidationProvider',{attrs:{"name":"addressLine1","rules":{ regex: /^[a-zA-Z0-9 \\/-_',.]+$/, required: true, max: 100 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"addressLine1","autocomplete":"nope"},model:{value:(_vm.currentCustomer.addressLine1),callback:function ($$v) {_vm.$set(_vm.currentCustomer, "addressLine1", $$v)},expression:"currentCustomer.addressLine1"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{staticClass:"mt-2 float-right",attrs:{"for":"inputEmail3"}},[_vm._v("Address Line 2:")])]),_c('b-col',{attrs:{"sm":"5"}},[_c('ValidationProvider',{attrs:{"name":"addressLine2","rules":{ regex: /^[a-zA-Z0-9 \\/-_',.]+$/, required: false, max: 100 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"addressLine2","autocomplete":"nope"},model:{value:(_vm.currentCustomer.addressLine2),callback:function ($$v) {_vm.$set(_vm.currentCustomer, "addressLine2", $$v)},expression:"currentCustomer.addressLine2"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{staticClass:"mt-2 float-right",attrs:{"for":"inputEmail3"}},[_vm._v("City:")])]),_c('b-col',{attrs:{"sm":"3"}},[_c('ValidationProvider',{attrs:{"name":"city","rules":{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max: 100 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"city","autocomplete":"nope"},model:{value:(_vm.currentCustomer.suburb),callback:function ($$v) {_vm.$set(_vm.currentCustomer, "suburb", $$v)},expression:"currentCustomer.suburb"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{staticClass:"mt-2 float-right",attrs:{"for":"inputEmail3"}},[_vm._v("Postcode:")])]),_c('b-col',{attrs:{"sm":"3"}},[_c('ValidationProvider',{attrs:{"name":"Postcode","rules":{ regex: /^[0-9]+$/, required: true, max: 10 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"Postcode","autocomplete":"nope"},model:{value:(_vm.currentCustomer.postcode),callback:function ($$v) {_vm.$set(_vm.currentCustomer, "postcode", $$v)},expression:"currentCustomer.postcode"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('h4',{staticClass:"header-title"},[_vm._v("Contact Details")]),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{staticClass:"mt-2 float-right",attrs:{"for":"inputEmail3"}},[_vm._v("Phone:")])]),_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Phone","rules":{ regex: /^[+0-9 ]+$/, required: true, max:15 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"Phone","autocomplete":"nope"},model:{value:(_vm.currentCustomer.phone),callback:function ($$v) {_vm.$set(_vm.currentCustomer, "phone", $$v)},expression:"currentCustomer.phone"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{staticClass:"mt-2 float-right",attrs:{"for":"inputEmail3"}},[_vm._v("Email Address:")])]),_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"email","rules":{ email:true, required: true, max: 50 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"email","autocomplete":"nope"},model:{value:(_vm.currentCustomer.email),callback:function ($$v) {_vm.$set(_vm.currentCustomer, "email", $$v)},expression:"currentCustomer.email"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{staticClass:"mt-2 float-right",attrs:{"for":"inputEmail3"}},[_vm._v(" ")])]),_c('b-col',{attrs:{"sm":"6"}},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.saveCustomer}},[_vm._v("Submit")])])],1)],1)]}}],null,false,529953645)})],1)])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }