<template>
<div>
    <div class="row page-title align-items-center">
        <div class="col-md-3 col-xl-6">
            <h4 class="mb-1 mt-0">{{ pageTitle }} <span v-if="currentCustomer.name">: {{ currentCustomer.name }}</span></h4>
        </div>
        <div class="col-md-9 col-xl-6 text-md-right">
            <div class="mt-4 mt-md-0">
                <button type="button" @click="goback()" class="btn btn-dark mr-2 mb-3 mb-sm-0">
                    <i class="mr-1"></i> Go Back
                </button>
            </div>
        </div>
    </div>
    <div class="card" v-if="currentCustomer">
        <div class="card-body">
            <div class="row">
                <div class="col-md-12 col-xl-7">
                    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                        <form class="form-horizontal" @submit.prevent="handleSubmit(saveCustomer)" autocomplete="nope">

                            <h4 class="header-title">Location Name</h4>
                            <b-row class="mb-3">
                                <b-col sm="3">
                                    <label class="mt-2 float-right" for="inputEmail3">Name</label>
                                </b-col>
                                <b-col sm="6">
                                    <ValidationProvider v-slot="{ errors }" name="locationname" :rules="{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max: 100 }" :custom-messages="customMessages">
                                        <b-form-input v-model="currentCustomer.name" name="locationname" autocomplete="nope"></b-form-input>
                                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>

                            <b-row class="mb-3">
                                <b-col sm="3">
                                    <label class="mt-2 float-right" for="inputEmail3">ABN</label>
                                </b-col>
                                <b-col sm="3">
                                    <ValidationProvider v-slot="{ errors }" name="abn" :rules="{ regex: /^[0-9]+$/, required: true, min:11, max: 11 }" :custom-messages="customMessages">
                                        <b-form-input v-model="currentCustomer.abn" name="abn" autocomplete="nope"></b-form-input>
                                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>

                            <h4 class="header-title">Address</h4>

                            <b-row class="mb-3">
                                <b-col sm="3">
                                    <label class="mt-2 float-right" for="inputEmail3">Search Address:</label>
                                </b-col>
                                <b-col sm="7">
                                    <addressSuggest @getAddress="getAddress"></addressSuggest>
                                </b-col>
                            </b-row>

                            <b-row class="mb-3">
                                <b-col sm="3">
                                    <label class="mt-2 float-right" for="inputEmail3">Country:</label>
                                </b-col>
                                <b-col sm="4">
                                    <ValidationProvider v-slot="{ errors }" :rules="{ required: true, max: 100 }" :custom-messages="customMessages">
                                        <b-form-select v-model="currentCustomer.country" name="state" class="mb-2" @change="onCountrySelected">
                                            <option v-for="(item,index) in countryList" :key="index" :value="item.countryCode" :text="item.country">{{ item.country }}</option>
                                        </b-form-select>
                                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>

                            <b-row class="mb-3">
                                <b-col sm="3">
                                    <label class="mt-2 float-right" for="inputEmail3">State:</label>
                                </b-col>
                                <b-col sm="4">
                                    <ValidationProvider v-slot="{ errors }" :rules="{ required: true, max: 100 }" :custom-messages="customMessages">
                                        <b-form-select v-model="currentCustomer.state" name="state" class="mb-2">
                                            <option v-for="(item,index) in stateList" :key="index" :value="item.stateCode" :text="item.state">{{ item.state }}</option>
                                        </b-form-select>
                                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>

                            <b-row class="mb-3">
                                <b-col sm="3">
                                    <label class="mt-2 float-right" for="inputEmail3">Address Line 1:</label>
                                </b-col>
                                <b-col sm="5">
                                    <ValidationProvider v-slot="{ errors }" name="addressLine1" :rules="{ regex: /^[a-zA-Z0-9 \\/-_',.]+$/, required: true, max: 100 }" :custom-messages="customMessages">
                                        <b-form-input v-model="currentCustomer.addressLine1" name="addressLine1" autocomplete="nope"></b-form-input>
                                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>

                            <b-row class="mb-3">
                                <b-col sm="3">
                                    <label class="mt-2 float-right" for="inputEmail3">Address Line 2:</label>
                                </b-col>
                                <b-col sm="5">
                                    <ValidationProvider v-slot="{ errors }" name="addressLine2" :rules="{ regex: /^[a-zA-Z0-9 \\/-_',.]+$/, required: false, max: 100 }" :custom-messages="customMessages">
                                        <b-form-input v-model="currentCustomer.addressLine2" name="addressLine2" autocomplete="nope"></b-form-input>
                                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>

                            <b-row class="mb-3">
                                <b-col sm="3">
                                    <label class="mt-2 float-right" for="inputEmail3">City:</label>
                                </b-col>
                                <b-col sm="3">
                                    <ValidationProvider v-slot="{ errors }" name="city" :rules="{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max: 100 }" :custom-messages="customMessages">
                                        <b-form-input v-model="currentCustomer.suburb" name="city" autocomplete="nope"></b-form-input>
                                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>

                            <b-row class="mb-3">
                                <b-col sm="3">
                                    <label class="mt-2 float-right" for="inputEmail3">Postcode:</label>
                                </b-col>
                                <b-col sm="3">
                                    <ValidationProvider v-slot="{ errors }" name="Postcode" :rules="{ regex: /^[0-9]+$/, required: true, max: 10 }" :custom-messages="customMessages">
                                        <b-form-input v-model="currentCustomer.postcode" name="Postcode" autocomplete="nope"></b-form-input>
                                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>

                            <h4 class="header-title">Contact Details</h4>

                            <b-row class="mb-3">
                                <b-col sm="3">
                                    <label class="mt-2 float-right" for="inputEmail3">Phone:</label>
                                </b-col>
                                <b-col sm="6">
                                    <ValidationProvider name="Phone" :rules="{ regex: /^[+0-9 ]+$/, required: true, max:15 }" v-slot="{ errors }" :custom-messages="customMessages">
                                        <b-form-input v-model="currentCustomer.phone" name="Phone" autocomplete="nope"></b-form-input>
                                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>

                            <b-row class="mb-3">
                                <b-col sm="3">
                                    <label class="mt-2 float-right" for="inputEmail3">Email Address:</label>
                                </b-col>
                                <b-col sm="6">
                                    <ValidationProvider v-slot="{ errors }" name="email" :rules="{ email:true, required: true, max: 50 }" :custom-messages="customMessages">
                                        <b-form-input v-model="currentCustomer.email" name="email" autocomplete="nope"></b-form-input>
                                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>

                            <b-row class="mb-3">
                                <b-col sm="3">
                                    <label class="mt-2 float-right" for="inputEmail3">&nbsp;</label>
                                </b-col>
                                <b-col sm="6">
                                    <button type="button" class="btn btn-primary" @click="saveCustomer">Submit</button>
                                </b-col>
                            </b-row>

                        </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import addressSuggest from '@components/addressSuggest'
import Layout from '@layouts/main'
import PageHeader from '@components/page-header'

import {
    treeData
} from '@state/api/tree';

import {
    customMessages
} from '@utils/validationmessage'

import {
    companyData
} from '@state/api/company';

export default {
    page: {
        title: 'Locations',
        meta: [{
            name: 'My Locations',
            content: ""
        }],
    },
    components: {
        addressSuggest,
        Layout,
        PageHeader
    },
    data() {
        return {
            customMessages,
            address: "",
            stateList: [],
            countryList: [],
            pageTitle: 'Add New Customer',
            currentCustomer: {}
        }
    },
    computed: {
        customerId() {
            return this.$route.params.id;
        }
    },
    async created() {
        this.countryList = await treeData.searchCountry();
        await this.loadCompany();
        await this.onCountrySelected();
    },
    methods: {
        async onCountrySelected() {
            if (this.currentCustomer) {
                this.stateList = await treeData.searchState(this.currentCustomer.country);
            }
        },
        async getAddress(address) {
            this.currentCustomer = {
                ...this.currentCustomer,
                ...address
            };
            await this.onCountrySelected();
        },
        async loadCompany() {
            if (this.customerId === undefined) {
                this.currentCustomer = {};
            } else {
                this.currentCustomer = await companyData.getByKey(this.customerId);
                this.pageTitle = "Manage";
            }
        },
        goback() {
            this.$router.back();
        },
        async saveCustomer() {
            const success = await this.$refs.form.validate();
            if (!success) {
                return;
            }

            let apiResult = await companyData.createCustomer(this.currentCustomer);
            if (!apiResult.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: apiResult.message,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }

            this.$vs.notify({
                title: "Success",
                text: "Record Saved",
                color: "success",
                position: "top-center"
            });
            this.$router.back();
        }
    }
}
</script>

<style lang="scss">
#profile-tabs {
    .vs-tabs--content {
        padding: 0;
    }
}
</style>
