<template>
<div>
    <input id="autocomplete" ref="autocomplete" class="form-control" placeholder="Search" />
    <gmaps-map style="display:none;" @mounted="ready">
    </gmaps-map>
</div>
</template>

<script>
import {
    gmapsMap,
} from 'x5-gmaps'

export default {

    components: {
        gmapsMap,
    },

    data() {
        return {
            markers: [],
            places: [],
            currentPlace: null,
            addressData: {},
            selectedAddress: {},
        };
    },

    methods: {
        ready(map) {
            this.map = map
            this.$GMaps().then((maps) => {
                this.places = new maps.places.PlacesService(map)
                this.autocomplete = new maps.places.Autocomplete(this.$refs.autocomplete)
                this.autocomplete.addListener('place_changed', this.setPlace)
            })
        },
        setPlace() {
            this.currentPlace = this.autocomplete.getPlace();
            const lat = this.currentPlace.geometry.location.lat();
            const lng = this.currentPlace.geometry.location.lng();
            const PlaceId = this.currentPlace.place_id;

            let componentForm = {
                subpremise: "short_name",
                street_number: "short_name",
                route: "long_name",
                locality: "long_name",
                administrative_area_level_1: "short_name",
                country: "short_name",
                postal_code: "short_name",
            };

            this.addressData = {};

            for (var i = 0; i < this.currentPlace.address_components.length; i++) {
                var addressType = this.currentPlace.address_components[i].types[0];
                if (componentForm[addressType]) {
                    var val = this.currentPlace.address_components[i][componentForm[addressType]];

                    this.addressData = {
                        ...this.addressData,
                        [addressType]: val,
                    };
                } else {
                    this.addressData = {
                        ...this.addressData,
                        [addressType]: "",
                    };
                }
                if (this.currentPlace.address_components.length === i + 1) {
                    if (this.addressData.subpremise) {
                        this.selectedAddress = {
                            ...this.selectedAddress,
                            addressLine1: this.addressData.subpremise,
                            addressLine2: this.addressData.street_number + " " + this.addressData.route,
                            suburb: this.addressData.locality,
                            city: this.addressData.locality,
                            state: this.addressData.administrative_area_level_1,
                            postcode: this.addressData.postal_code,
                            latitude: lat,
                            longitude: lng,
                            addressPlaceId: PlaceId,
                            country: this.addressData.country
                        };
                        return;
                    }
                    this.selectedAddress = {
                        ...this.selectedAddress,
                        addressLine1: this.addressData.street_number,
                        addressLine2: this.addressData.route,
                        suburb: this.addressData.locality,
                        city: this.addressData.locality,
                        state: this.addressData.administrative_area_level_1,
                        postcode: this.addressData.postal_code,
                        latitude: lat,
                        longitude: lng,
                        addressPlaceId: PlaceId,
                        country: this.addressData.country
                    };
                }
            }
            this.$emit("getAddress", this.selectedAddress);
        },
    },
};
</script>

<style scoped>
.formfield {
    padding: 11px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
</style>
